import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import * as Sentry from '@sentry/browser'

import { create, history } from './store/store'

import './config/i18n'
import App from './app/app'
import './styles/index.scss'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.API_URL?.match(/score-service.send4./) ? 'production' : 'development',
  defaultIntegrations: false
})

const store = create()

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.querySelector('#root')
)
