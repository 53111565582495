import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import app from './app'
import score from './score'
import pagination from './pagination'
import filter from './filter'

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    app,
    score,
    pagination,
    filter
  })

export default createRootReducer
