import React from 'react'

export default function AftersaleHeartMono() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0022 5.99254L7.58004 2.33025C7.38044 2.13227 7.1107 2.02118 6.82957 2.02118C6.54844 2.02118 6.2787 2.13227 6.0791 2.33025L0.345517 8.334C0.144052 8.55289 0.0322266 8.83951 0.0322266 9.137C0.0322266 9.43449 0.144052 9.72111 0.345517 9.94L1.84646 11.531C1.94583 11.634 2.06467 11.7162 2.19607 11.7729C2.32748 11.8296 2.46883 11.8596 2.61193 11.8612L5.38867 11.9963L11.0022 5.99254Z"
        fill="#5C4D85"
      />
      <path
        d="M11.2271 21.4222L5.49347 15.4184C5.292 15.1995 5.18018 14.9129 5.18018 14.6154C5.18018 14.3179 5.292 14.0313 5.49347 13.8124L7.33962 11.8612L16.4203 2.33025C16.6199 2.13227 16.8896 2.02118 17.1708 2.02118C17.4519 2.02118 17.7216 2.13227 17.9212 2.33025L23.6548 8.334C23.8563 8.55289 23.9681 8.83951 23.9681 9.137C23.9681 9.43449 23.8563 9.72111 23.6548 9.94L12.758 21.4222C12.5584 21.6202 12.2887 21.7312 12.0075 21.7312C11.7264 21.7312 11.4567 21.6202 11.2571 21.4222"
        fill="#5C4D85"
      />
    </svg>
  )
}
