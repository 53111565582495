import { AxiosResponse } from 'axios'
import download from 'downloadjs'

import { createFetcher } from '../fetcher'

import { IScoreFilter } from '../../interfaces/score'
import { mountQuery } from '../../utils/mount-query'

const fetch = createFetcher()

export async function getResult(): Promise<AxiosResponse | any> {
  try {
    const response = await fetch.get('/v1/score/results')
    return response
  } catch (error) {
    return error.response
  }
}

export async function getScore(params: IScoreFilter = {}): Promise<AxiosResponse | any> {
  const query = mountQuery(params)

  try {
    const response = await fetch.get(`/v1/score${query}`)
    return response
  } catch (error) {
    return error.response
  }
}

export async function exportData(params: IScoreFilter = {}) {
  const query = mountQuery(params)

  try {
    const response = await fetch.get(`/v1/score/download/csv${query}`)
    download(response.data, `export-score-data-${Date.now()}.csv`)

    return response
  } catch (error) {
    return error.response
  }
}
