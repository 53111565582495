import { IScore } from '../../interfaces/score'
import { IRules } from '../../interfaces/rules'

export const getScoreList = () => ({
  type: 'ASYNC_GET_SCORE_LIST'
})

export const setScoreList = (payload: IScore[]) => ({
  type: 'SET_SCORE_LIST',
  payload
})

export const resetScoreList = () => ({
  type: 'RESET_SCORE_LIST'
})

export const getRulesList = () => ({
  type: 'ASYNC_GET_RULES_LIST'
})

export const setRulesList = (payload: IRules[]) => ({
  type: 'SET_RULES_LIST',
  payload
})

export const resetRulesList = () => ({
  type: 'RESET_RULES_LIST'
})

export const setTotal = (payload: number) => ({
  type: 'SET_TOTAL',
  payload
})

export const resetTotal = () => ({
  type: 'RESET_TOTAL'
})

export const getScoreReport = () => ({
  type: 'ASYNC_GET_SCORE_REPORT'
})
