import { call, put } from 'redux-saga/effects'
import * as localStorageHelper from '../../utils/storage'

import { setAppToken, setAppReady } from '../actions/app'
import { LOCALSTORAGE_SCORE_TOKEN } from '../../config/constants'

interface Action {
  type: string
  payload: any
}

export function* getAppToken() {
  const token = localStorageHelper.getString(LOCALSTORAGE_SCORE_TOKEN)

  if (token) {
    yield put(setAppToken(token))
  }
}

export function* appBootstrap() {
  yield call(getAppToken)

  yield put(setAppReady(true))
}
