import React from 'react'

export default function IconStar() {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99955 14.05C10.1585 14.05 10.3175 14.088 10.4625 14.163L14.2315 16.133L13.5145 11.976C13.4575 11.65 13.5665 11.318 13.8045 11.087L16.8405 8.151L12.6375 7.539C12.3125 7.492 12.0315 7.289 11.8855 6.995L9.99955 3.201L8.11355 6.995C7.96755 7.289 7.68655 7.492 7.36155 7.539L3.15855 8.151L6.19455 11.087C6.43255 11.318 6.54155 11.65 6.48455 11.976L5.76755 16.133L9.53655 14.163C9.68155 14.088 9.84055 14.05 9.99955 14.05ZM15.5615 18.955C15.4025 18.955 15.2435 18.918 15.0985 18.842L9.99955 16.178L4.90055 18.842C4.56255 19.018 4.15455 18.987 3.84955 18.763C3.54155 18.539 3.38855 18.16 3.45255 17.785L4.42455 12.157L0.304547 8.172C0.0295468 7.907 -0.0684532 7.509 0.0485468 7.146C0.164547 6.783 0.478547 6.519 0.855547 6.464L6.55655 5.636L9.10355 0.51C9.44155 -0.17 10.5575 -0.17 10.8955 0.51L13.4425 5.636L19.1435 6.464C19.5205 6.519 19.8345 6.783 19.9505 7.146C20.0675 7.509 19.9695 7.907 19.6945 8.172L15.5745 12.157L16.5465 17.785C16.6105 18.16 16.4575 18.539 16.1495 18.763C15.9765 18.891 15.7695 18.955 15.5615 18.955Z"
      />
    </svg>
  )
}
