import React from 'react'

const DEFAULT_COLOR = '#9f9fa0'

interface Props {
  status: string
  color?: string
  number: number
  handleClick: (payload) => void
}

export function ResumeStatusCard(props: Props) {
  return (
    <div
      className="status-card-container status-card-container pointer"
      onClick={props.handleClick}
    >
      <span style={{ backgroundColor: props.color || DEFAULT_COLOR }} />
      <div className="flex flex-vertical flex-between-start">
        <h3>{props.number}</h3>
        <p>{props.status}</p>
      </div>
    </div>
  )
}

export default ResumeStatusCard
