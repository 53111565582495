import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/private-route'

import { appBootstrap } from '../store/actions/app'

import Home from './views/home'
import Login from './views/login'
import Logout from './views/logout'

interface Props {
  init: () => void
  ready: boolean
}

function App(props: Props) {
  useEffect(() => {
    props.init()
  }, [])

  return (
    <section className="app">
      {props.ready && (
        <Switch>
          <PrivateRoute exact path="/" component={Home} />

          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/logout" component={Logout} />
        </Switch>
      )}
    </section>
  )
}

const mapState = (state: any) => ({
  ready: state.app.ready || false
})

const mapDispatch = (dispatch: any) => ({
  init: () => dispatch(appBootstrap())
})

export default connect(mapState, mapDispatch)(App)
