import { IPagination } from '../../interfaces/pagination'

interface Action {
  type: string
  payload: IPagination
}

const initialState: IPagination = {
  total: 1,
  page: 1,
  prevPage: null,
  nextPage: null,
  limit: 30,
  newPage: null
}

const pagination = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_PAGINATION':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default pagination
