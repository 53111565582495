import React from 'react'

import Aside from '../components/aside'
import HeaderBar from '../components/header/bar'
import ScoreList from '../components/score/list'
import ScoreResume from '../components/score/resume'
import Pagination from '../components/pagination'

export default function Home() {
  return (
    <section>
      <HeaderBar />
      <Aside />

      <main className="main-container">
        <div className="score">
          <div className="score__content table section-max-width">
            <ScoreResume />
            <ScoreList />
            <Pagination />
          </div>
        </div>
      </main>
    </section>
  )
}
