import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { getScoreReport } from '../../../store/actions/score'

import ModernModal from '../modal'

import CloseIcon from '../icons/close'
import DownloadIcon from '../icons/download'
import CheckIcon from '../icons/check'

interface Props {
  getReport: () => void
}

export function ExportData(props: Props) {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  return (
    <div>
      <button
        type="button"
        className="context-sensitive-menu-circular-button"
        title={t('titles.export')}
        onClick={() => setVisible(true)}
      >
        <DownloadIcon />
      </button>

      <ModernModal visible={visible} onClose={() => setVisible(false)} noCloseButton={true}>
        <div className="mg-20 m-width-50">
          <p className="mg-bottom-30 text-center">{t('descriptions.export_data_message')}</p>
          <div className="flex flex-center">
            <button
              type="button"
              onClick={() => {
                props.getReport()
                setVisible(false)
              }}
              className="btn btn-sm btn-success"
            >
              <CheckIcon />
              {t('titles.yes_export')}
            </button>
            <button
              type="button"
              onClick={() => setVisible(false)}
              className="btn btn-sm btn-danger"
            >
              <CloseIcon />
              {t('titles.no')}
            </button>
          </div>
        </div>
      </ModernModal>
    </div>
  )
}

const mapDispatch = (dispatch: any) => ({
  getReport: () => dispatch(getScoreReport())
})

export default connect(null, mapDispatch)(ExportData)
