import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { IRules } from '../../../../interfaces/rules'
import { IPagination } from '../../../../interfaces/pagination'

import { resetFilters, setFilterResults } from '../../../../store/actions/filter'
import { setPagination } from '../../../../store/actions/pagination'

import { getRulesList, getScoreList } from '../../../../store/actions/score'

import ResumeOpenedCard from './opened'
import ResumeStatusCard from './status'
import ResumeTotalCard from './total'

interface Props {
  rules: IRules[]
  total: number
  pagination: IPagination
  getRules: () => void
  getScore: () => void
  setFilterResults: (payload) => void
  setPagination: (payload) => void
  resetFilters: () => void
}

function ScoreResume(props: Props) {
  const [filtered, setFiltered] = useState<IRules[]>([])

  const handleFilterResult = (label: string, value: string) => {
    props.setFilterResults([{ label, value }])
    props.setPagination({ ...props.pagination, page: 1 })
    props.getScore()
  }

  const handleResetFilter = () => {
    props.resetFilters()
    props.setPagination({ ...props.pagination, page: 1 })
    props.getScore()
  }

  useEffect(() => {
    if (props.rules.length > 0) {
      setFiltered(props.rules)
    }
  }, [props.rules])

  useEffect(() => {
    props.getRules()
  }, [])

  return (
    <div className="flex">
      <ResumeTotalCard count={props.total} handleClick={() => handleResetFilter()} />
      <ResumeOpenedCard />
      <div className="status-card-list-container">
        {filtered.length > 0 &&
          filtered.map(item => (
            <ResumeStatusCard
              key={item.id}
              handleClick={() => handleFilterResult(item.result, item.id)}
              status={item.result}
              color={item.color}
              number={item.count}
            />
          ))}
      </div>
    </div>
  )
}

const mapState = (state: any) => ({
  rules: state.score.rules || [],
  total: state.score.total || 0,
  pagination: state.pagination
})

const mapDispatch = (dispatch: any) => ({
  getRules: () => dispatch(getRulesList()),
  getScore: () => dispatch(getScoreList()),
  setFilterResults: (payload) => dispatch(setFilterResults(payload)),
  setPagination: payload => dispatch(setPagination(payload)),
  resetFilters: () => dispatch(resetFilters())
})

export default connect(mapState, mapDispatch)(ScoreResume)
