import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import InputText from '../input/input-text'

import { login } from '../../../store/actions/auth'

// @ts-ignore
import EmailIcon from '../../../assets/icon_email.png'
// @ts-ignore
import PasswordIcon from '../../../assets/icon_pass.png'

interface Props {
  doLogin: (payload: { email: string; password: string }) => void
}

function FormLogin(props: Props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { t } = useTranslation()

  const handleSubmit = e => {
    e.preventDefault()
    const errors = document.getElementById('form-login')?.querySelectorAll('#input-error')

    if (!errors || !errors.length) {
      props.doLogin({ email, password })
    }
  }

  return (
    <form className="form-login" onSubmit={e => handleSubmit(e)}>
      <div className="fields">
        <div className="relative">
          <InputText
            id="email"
            autoFocus={true}
            placeholder={t('titles.email')}
            value={email}
            isRequired={true}
            isEmail={true}
            onTextChange={text => setEmail(text)}
          />
          <span className="input-icon">
            <img src={EmailIcon} />
          </span>
        </div>

        <div className="relative">
          <InputText
            id="password"
            type="password"
            placeholder={t('titles.password')}
            value={password}
            isRequired={true}
            onTextChange={text => setPassword(text)}
          />
          <span className="input-icon">
            <img src={PasswordIcon} />
          </span>
        </div>
      </div>
      <div className="actions">
        <button type="submit" className="btn btn-block btn-primary--dark">
          {t('titles.sign_in')}
        </button>
      </div>
    </form>
  )
}

const mapDispatch = (dispatch: any) => ({
  doLogin: payload => dispatch(login(payload))
})

export default connect(null, mapDispatch)(FormLogin)
