import React from 'react'
import { formatToBRL } from 'brazilian-values'

import { IScore } from '../../../../interfaces/score'
import { hexToRGB } from '../../../../utils/hex-to-rgb'

interface Props {
  item: IScore
  color: string
}

export function renderDate(date: string | number): string {
  const dateObject = new Date(date)
  return new Intl.DateTimeFormat('pt-Br').format(dateObject)
}

export function renderStatus(label: string, color: string) {
  const styles = {
    color: color,
    backgroundColor: hexToRGB(color, '0.1')
  }

  return (
    <div className="badget status" style={styles}>
      <span>{label}</span>
    </div>
  )
}

export function ScoreItem(props: Props) {
  return (
    <div className="table-item">
      <span className="table-item-col">{renderStatus(props.item.result, props.color)}</span>
      <span className="table-item-col">{props.item.order}</span>
      <span className="table-item-col">{props.item.code}</span>
      <span className="table-item-col">{formatToBRL(props.item.total_value) ?? 0}</span>
      <span className="table-item-col">{props.item.score}</span>
      <span className="table-item-col">{renderDate(props.item.created_at)}</span>
    </div>
  )
}

export default ScoreItem
