import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ScoreItem from './item'
import TableHeadItem from './head'

import { getScoreList } from '../../../../store/actions/score'

import { IScore } from '../../../../interfaces/score'
import { IRules } from '../../../../interfaces/rules'

const DEFAULT_COLOR = '#9f9fa0'

interface Props {
  items: IScore[]
  rules: IRules[]
  getData: () => void
}

const headItems = ['status', 'id', 'order', 'amount', 'score', 'date']

function ScoreList(props: Props) {
  const { t } = useTranslation()

  const handleItemColor = (label: string): string => {
    const target = props.rules.find((item: IRules) => item.result === label)

    if (target?.color) return target.color
    return DEFAULT_COLOR
  }

  useEffect(() => {
    props.getData()
  }, [])

  return (
    <>
      {props.rules.length > 0 && props.items.length > 0 ? (
        <div className="table-default table-default__min-width-fhd">
          <div className="table-head">
            {headItems.map((item: string, index: number) => (
              <TableHeadItem key={index} label={t(`score.table_${item}`)} />
            ))}
          </div>

          {props.items.map(item => (
            <ScoreItem key={item.id} item={item} color={handleItemColor(item.result)} />
          ))}
        </div>
      ) : (
        <p>{t('warnings.empty_score_list')}</p>
      )}
    </>
  )
}

const mapState = (state: any) => ({
  items: state.score.list || [],
  rules: state.score.rules || []
})

const mapDispatch = (dispatch: any) => ({
  getData: () => dispatch(getScoreList())
})

export default connect(mapState, mapDispatch)(ScoreList)
