import React from 'react'

export default function DownloadIcon() {
  return (
    <svg
      className="svg-icon"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00284 9.0759C7.00096 9.05084 7 9.02553 7 9V1C7 0.448 7.447 0 8 0C8.553 0 9 0.448 9 1V8.99982L11.4 7.2C11.842 6.867 12.469 6.958 12.8 7.4C13.132 7.842 13.042 8.469 12.6 8.8L8.6 11.8C8.423 11.933 8.211 12 8 12C7.799 12 7.598 11.939 7.425 11.818L3.425 9.004C2.973 8.686 2.864 8.062 3.182 7.611C3.5 7.159 4.123 7.05 4.575 7.368L7.00284 9.0759ZM2 14V15H14V14C14 13.45 14.45 13 15 13C15.55 13 16 13.45 16 14V16C16 16.55 15.55 17 15 17H1C0.45 17 0 16.55 0 16V14C0 13.45 0.45 13 1 13C1.55 13 2 13.45 2 14Z"
      />
    </svg>
  )
}
