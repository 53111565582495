export function hexToRGB(hex: string, alpha: string = '') {
  hex = hex.substr(1)

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((el: string) => {
        return el + el + ''
      })
      .join('')
  }

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}
