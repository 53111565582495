import ptbr from './pt-br.yaml'
import enus from './en-us.yaml'

export const resources = {
  ptBR: {
    translation: ptbr
  },
  enUS: {
    translation: enus
  }
}
