import React from 'react'
import { useTranslation } from 'react-i18next'

import MenuItem from './item'

import IconStar from '../icons/aside-star'
import IconUser from '../icons/aside-user'

export function MiddleAsideMenu() {
  const { t } = useTranslation()

  return (
    <nav className="aside-menu">
      <ul className="menu-list unstyled-list no-toggled">
        <MenuItem desc={t('menu.delivery_list')} icon={<IconStar />} href="/" />
      </ul>
    </nav>
  )
}

export function BottomAsideMenu() {
  const { t } = useTranslation()

  return (
    <div className="menu-list unstyled-list menu-user">
      <section>
        <div>
          <nav className="aside-menu">
            <ul className="menu-list unstyled-list no-toggled">
              <MenuItem desc={t('menu.logout')} icon={<IconUser />} href="/logout" />
            </ul>
          </nav>
        </div>
      </section>
    </div>
  )
}

export default function Menu() {
  return (
    <>
      <MiddleAsideMenu />
      <BottomAsideMenu />
    </>
  )
}
