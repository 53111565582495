import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputText } from '../../interfaces/input'

export function emailValidation(email: string) {
  if (typeof email === 'string' && email.length > 0) {
    const re = /^([a-z0-9_\-.+])+@([a-z0-9_\-.])+\.([a-z]{2,})$/i
    return re.test(String(email).toLowerCase())
  }

  return false
}

export default function InputText(props: IInputText) {
  const [error, setError] = useState<string | null>(null)
  const [focus, setFocus] = useState(false)
  const { t } = useTranslation()

  const validation = (text: string) => {
    const trimmed = text.trim()
    if (props.isRequired && !trimmed) {
      setError(t('errors.required'))
    } else {
      if (props.isEmail && !emailValidation(trimmed)) {
        setError(t('errors.email_validation'))
      } else {
        setError(null)
      }
    }
  }

  const onBlur = (event: any) => {
    validation(event.target.value)
    setFocus(!focus)
  }

  const onFocus = () => setFocus(!focus)

  const handleChange = (event: any) => {
    validation(event.target.value)
    props.onTextChange(event.target.value)
  }

  return (
    <div
      className="input-text"
      data-status={error || props.error ? 'error' : 'normal'}
      data-enabled={props.disabled ? 'disabled' : ''}
    >
      {props.label && (
        <label id="input-label" htmlFor={props.id}>
          {props.label}
          {props.isRequired && !props.disabled && <span className="required">&nbsp;*</span>}
        </label>
      )}
      <input
        id={props.id}
        autoComplete="off"
        autoFocus={props.autoFocus || false}
        type={props.type || 'text'}
        placeholder={props.placeholder ?? ''}
        name={props.id}
        required={props.isRequired || false}
        value={props.value}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {(error || props.error) && (
        <p id="input-error">{error || props.error}</p>
      )}
    </div>
  )
}
