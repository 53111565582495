import React from 'react'
import { useTranslation } from 'react-i18next'

import AftersaleBackgroundPattern from '../components/aftersale-backgroundpattern'
import AftersaleLogo from '../components/icons/aftersale-logo'
import FormLogin from '../components/form/login'

export default function Login() {
  const { t } = useTranslation()

  return (
    <div className="login">
      <div className="login__left-panel">
        <div className="login__left-panel__logo">
          <AftersaleLogo />
        </div>

        <div className="login__left-panel__content">
          <FormLogin key="form-login" />

          <div className="login__left-panel__middle">
            <span key="recovery-text" className="pointer text-italic text-underline mg-top-20">
              {t('descriptions.forgot_password')}
            </span>
          </div>
        </div>
      </div>

      <div className="login__background-pattern">
        <AftersaleBackgroundPattern />
      </div>
    </div>
  )
}
