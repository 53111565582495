import React from 'react'
import { Link } from 'react-router-dom'

import AftersaleHeartMono from '../icons/aftersale-logo-mono'

export default function HeaderLogo() {
  return (
    <Link to="/" className="logo">
      <div className="logo-body">
        <AftersaleHeartMono />
      </div>
    </Link>
  )
}
