import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'

import SearchIcon from '../../icons/search'
import CloseIcon from '../../icons/close'

import { IScoreFilter } from '../../../../interfaces/score'
import { IRules } from '../../../../interfaces/rules'
import { IPagination } from '../../../../interfaces/pagination'

import InputSelectWithSearch from '../../input/input-select-w-search'
import InputDate from '../../input/input-date'

import {
  setFilterStartDate,
  setFilterEndDate,
  setFilterResults,
  resetFilters,
  setFilterOrder
} from '../../../../store/actions/filter'
import { getScoreList } from '../../../../store/actions/score'
import { setPagination } from '../../../../store/actions/pagination'
import InputText from '../../input/input-text'

interface Props {
  onClose: () => void
  resetFilters: () => void
  applyFilters: () => void
  setPagination: (payload) => void
  setFilterStartDate: (payload) => void
  setFilterEndDate: (payload) => void
  setFilterResults: (payload) => void
  setFilterOrder: (payload) => void
  filter: IScoreFilter
  rules: IRules[]
  pagination: IPagination
}

function ScoreFilter(props: Props) {
  const { t } = useTranslation()
  const [rules, setRules] = useState<{ label: string; value: string }[]>([])
  const [filter, setFilter] = useState<any>({
    order: '',
    startDate: '',
    endDate: '',
    results: []
  })

  const handleApply = e => {
    e.preventDefault()
    props.setPagination({ ...props.pagination, page: 1 })
    props.applyFilters()
  }

  const readyToClear = (): boolean => {
    if (!filter) return false

    const { order, startDate, endDate, results } = filter

    if (order.length || startDate.length || endDate.length || results.length) {
      return true
    }

    return false
  }

  const handleRules = (rules: IRules[]) => {
    const formated = rules.map(item => ({ label: item.result, value: item.id }))
    setRules(formated)
  }

  const handleReset = () => {
    props.resetFilters()
    props.setPagination({ ...props.pagination, page: 1 })
    props.onClose()
    props.applyFilters()
  }

  useEffect(() => {
    setFilter(props.filter)
    handleRules(props.rules)
  }, [props.filter, props.rules])

  return (
    <OutsideClickHandler onOutsideClick={() => props.onClose()}>
      <div className="filter-container">
        <div className="filter-container__header">
          <h3>{t('score.title')}</h3>
          <div className="filter-container__header__action-buttons">
            <button title={t('titles.cancel')} onClick={() => props.onClose()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <form className="filter-container__body" onSubmit={handleApply}>
          <div className="reverses-filter-item grid grid-col-lg-1 grid-col-md-1 grid-col-sm-1 grid-col-xs-1 mg-bottom-20 full-width">
            <InputText
              id="filter-order"
              value={props.filter.order}
              label={t('descriptions.filter_order')}
              onTextChange={selected => props.setFilterOrder(selected)}
            />
          </div>

          <div className="grid grid-col-lg-1 grid-col-md-1 grid-col-sm-1 grid-col-xs-1 mg-bottom-20 full-width">
            <InputSelectWithSearch
              id="filter-select-rule"
              options={rules || []}
              multi={true}
              value={props.filter.results}
              label={t('descriptions.filter_result')}
              emptyText={t('descriptions.select_an_option')}
              noOptionsText={t('descriptions.empty_options')}
              handleSelect={selected => props.setFilterResults(selected ?? [])}
            />
          </div>

          <div className="grid grid-col-lg-2 grid-col-sm-1 grid-col-xs-1 mg-bottom-20 full-width">
            <InputDate
              onTextChange={props.setFilterStartDate}
              value={props.filter.startDate ?? ''}
              onlyDate={true}
              label={t('titles.start_date')}
              id="filter-start-date"
              isRequired={false}
              placeholder=""
            />

            <InputDate
              onTextChange={props.setFilterEndDate}
              value={props.filter.endDate ?? ''}
              onlyDate={true}
              label={t('titles.end_date')}
              id="filter-end-date"
              isRequired={false}
              min={props.filter.startDate}
              placeholder=""
            />
          </div>

          <div className="grid grid-col-lg-2 grid-col-md-2 grid-col-sm-1 full-width">
            <button className="button primary-full" type="submit">
              <SearchIcon />
              <p>{t('titles.find')}</p>
            </button>
            {readyToClear() && (
              <button className="button__secondary" onClick={handleReset}>
                <p>{t('titles.clear')}</p>
              </button>
            )}
          </div>
        </form>
      </div>
    </OutsideClickHandler>
  )
}

const mapState = (state: any) => ({
  filter: state.filter,
  rules: state.score.rules || [],
  pagination: state.pagination
})

const mapDispatch = (dispatch: any) => ({
  setFilterStartDate: payload => dispatch(setFilterStartDate(payload)),
  setFilterEndDate: payload => dispatch(setFilterEndDate(payload)),
  setFilterResults: payload => dispatch(setFilterResults(payload)),
  setFilterOrder: payload => dispatch(setFilterOrder(payload)),
  setPagination: payload => dispatch(setPagination(payload)),
  resetFilters: () => dispatch(resetFilters()),
  applyFilters: () => dispatch(getScoreList())
})

export default connect(mapState, mapDispatch)(ScoreFilter)
