import axios, { AxiosRequestConfig } from 'axios'

import * as localStorageHelper from '../utils/storage'
import { LOCALSTORAGE_SCORE_TOKEN } from '../config/constants'

export function createFetcher() {
  const instance = axios.create({
    baseURL: process.env.API_URL
  })

  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = localStorageHelper.getString(LOCALSTORAGE_SCORE_TOKEN)

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      config.headers['Content-Type'] = 'application/json'
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  return instance
}
