import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FilterIcon from '../icons/filter'
import FilterScore from '../score/filter'

export default function Filter() {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div>
      <div className="dropdown-button">
        <div className="dropdown-button__default-action-container">
          <button
            className="context-sensitive-menu-button"
            onClick={() => setIsOpen(!isOpen)}
            title={t('titles.filter')}
          >
            <FilterIcon />
            <p>{t('titles.filter')}</p>
          </button>
        </div>
      </div>
      {isOpen && (
        <FilterScore onClose={() => setIsOpen(false)} />
      )}
    </div>
  )
}
