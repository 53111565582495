interface Login {
  email: string
  password: string
}

export const login = (payload: Login) => ({
  type: 'ASYNC_LOGIN_USER',
  payload
})

export const logout = () => ({
  type: 'ASYNC_LOGOUT_USER'
})
