const initialState = {
  order: '',
  startDate: '',
  endDate: '',
  results: []
}

const filter = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_FILTER_RESULTS':
      return { ...state, results: action.payload }
    case 'SET_FILTER_ORDER':
      return { ...state, order: action.payload }
    case 'SET_FILTER_START_DATE':
      return { ...state, startDate: action.payload }
    case 'SET_FILTER_END_DATE':
      return { ...state, endDate: action.payload }
    case 'RESET_FILTERS':
      return { ...initialState }
    default:
      return state
  }
}

export default filter
