import React from 'react'

interface Props {
  icon: React.ReactNode
  desc: string
  href: string
}

export function MenuItem(props: Props) {
  return (
    <li className="aside-menu-item">
      <a href={props.href}>
        <span className="menu-icon">{props.icon}</span>
      </a>
      <span id="hover-description">{props.desc}</span>
    </li>
  )
}

export default MenuItem
