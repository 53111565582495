import React from 'react'
import { useTranslation } from 'react-i18next'

import ReversesIcon from '../../icons/aside-reverses'

interface Props {
  count?: number
  handleClick: (payload) => void
}

export function ResumeTotalCard(props: Props) {
  const { t } = useTranslation()

  return (
    <div
      title={`${t('score.resume_card_total')}: ${props.count || 0}`}
      className="status-card-container flex flex-vertical status-card-container-square pointer"
      onClick={props.handleClick}
    >
      <ReversesIcon />
    </div>
  )
}

export default ResumeTotalCard
