import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { IInputWSelect } from '../../interfaces/input'

export default function InputSelectWithSearch(props: IInputWSelect) {
  return (
    <div className="select-items">
      <label className="label">{props.label}</label>
      <div className="select-with-search">
        <Select
          styles={customStyles}
          classNamePrefix="select-w-search"
          theme={customTheme}
          components={makeAnimated()}
          options={props.options}
          defaultValue={props.value || null}
          onChange={props.handleSelect}
          placeholder={props.emptyText}
          noOptionsMessage={() => props.noOptionsText}
          autoFocus={props.autoFocus || false}
          isMulti={props.multi}
          isDisabled={props.disabled || false}
        />
      </div>
    </div>
  )
}

const customStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: isDisabled ? 'not-allowed' : 'default'
    }
  },
  control: styles => ({
    ...styles,
    border: 'none',
    borderRadius: '0',
    boxShadow: 'none',
    backgroundColor: 'var(--card-background-color)'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    backgroundColor: 'var(--separator-color)'
  }),
  multiValue: styles => {
    return {
      ...styles,
      borderRadius: '4px',
      backgroundColor: 'var(--primary-color)',
      fontWeight: 'bold'
    }
  },
  multiValueLabel: styles => ({
    ...styles,
    borderRadius: '4px',
    backgroundColor: 'var(--primary-color)',
    color: 'var(--card-background-color)'
  }),
  multiValueRemove: styles => ({
    ...styles,
    borderRadius: '4px',
    backgroundColor: 'var(--danger-color)',
    color: 'var(--card-background-color)'
  })
}

const customTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--primary-color)',
    primary25: 'var(--primary-highlight)',
    primary75: 'var(--primary-color-dark)',
    primary50: 'var(--text-secondary-color)'
  }
})
