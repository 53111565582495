export const setFilterResults = (payload: string) => ({
  type: 'SET_FILTER_RESULTS',
  payload
})

export const setFilterOrder = (payload: string) => ({
  type: 'SET_FILTER_ORDER',
  payload
})

export const setFilterStartDate = (payload: string) => ({
  type: 'SET_FILTER_START_DATE',
  payload
})

export const setFilterEndDate = (payload: string) => ({
  type: 'SET_FILTER_END_DATE',
  payload
})

export const resetFilters = () => ({
  type: 'RESET_FILTERS'
})
