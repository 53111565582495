export const appBootstrap = () => ({
  type: 'APP_BOOTSTRAP'
})

export const setAppReady = (payload: boolean) => ({
  type: 'SET_APP_READY',
  payload
})

export const setAppLoading = (payload: boolean) => ({
  type: 'SET_APP_LOADING',
  payload
})

export const getAppToken = () => ({
  type: 'GET_APP_TOKEN'
})

export const setAppToken = (payload: string) => ({
  type: 'SET_APP_TOKEN',
  payload
})

export const resetAppToken = () => ({
  type: 'RESET_APP_TOKEN'
})

export const refreshAppToken = () => ({
  type: 'ASYNC_APP_REFRESH_TOKEN'
})
