import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { logout } from '../../store/actions/auth'

interface Props {
  logout: () => void
}

function Logout(props: Props) {
  useEffect(() => {
    props.logout()
  }, [])
  return <></>
}

const mapDispatch = (dispatch: any) => ({
  logout: () => dispatch(logout())
})

export default connect(null, mapDispatch)(Logout)
