import React from 'react'
import { useTranslation } from 'react-i18next'

import Filter from './filter'
import ExportData from './export-data'

export function HeaderBar(props) {
  const { t } = useTranslation()

  return (
    <header className="header-bar">
      <div id="container">
        <div className="breadcrumb">
          <div className="breadcrumb-item">
            <a href="/">{t('titles.score')}</a>
          </div>
        </div>
      </div>
      <div className="context-sensitive-menu">
        <Filter />
        <ExportData />
      </div>
    </header>
  )
}

export default HeaderBar
