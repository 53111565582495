import React from 'react'

interface Props {
  active?: boolean
}

export default function FilterIcon(props: Props) {
  return (
    <svg
      className="svg-icon"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-active={props.active ? 'on' : 'off'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87723 13.457L8.90323 14.99V10.437C8.90323 10.271 8.94523 10.108 9.02323 9.962L13.3232 2H2.64323L6.76523 9.978C6.83923 10.12 6.87723 10.278 6.87723 10.437V13.457ZM9.90323 18C9.69023 18 9.47723 17.932 9.30023 17.797L5.27423 14.752C5.02423 14.563 4.87723 14.268 4.87723 13.955V10.681L0.112229 1.459C-0.0487714 1.149 -0.0357714 0.778 0.146229 0.48C0.327229 0.182 0.651228 0 1.00023 0H15.0002C15.3522 0 15.6782 0.185 15.8592 0.488C16.0392 0.79 16.0472 1.165 15.8802 1.475L10.9032 10.69V17C10.9032 17.379 10.6892 17.726 10.3492 17.895C10.2082 17.965 10.0552 18 9.90323 18Z"
      />
    </svg>
  )
}
