const initialState = {
  list: [],
  rules: [],
  total: 0
}

const score = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_SCORE_LIST':
      return { ...state, list: action.payload }
    case 'RESET_SCORE_LIST':
      return { ...state, list: [] }
    case 'SET_RULES_LIST':
      return { ...state, rules: action.payload }
    case 'RESET_RULES_LIST':
      return { ...state, rules: [] }
    case 'SET_TOTAL':
      return { ...state, total: action.payload }
    case 'RESET_TOTAL':
      return { ...state, total: 0 }
    default:
      return state
  }
}

export default score
