import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputDate } from '../../interfaces/input'

export default function InputDate(props: IInputDate) {
  const [error, setError] = useState(null)
  const { t } = useTranslation()

  const handleChange = event => {
    props.onTextChange(event.target.value)

    if (props.isRequired && event.target.value === '') {
      setError(t('errors.required'))
    } else {
      setError(null)
    }
  }

  return (
    <div
      className="input-text"
      data-status={error ? 'error' : 'normal'}
      data-enabled={props.disabled ? 'disabled' : ''}
    >
      {props.label && (
        <label id="input-label" htmlFor={props.id}>
          {props.label}
          {props.isRequired && !props.disabled && <span className="required">&nbsp;*</span>}
        </label>
      )}
      <input
        autoComplete="off"
        autoFocus={props.autoFocus || false}
        type={props.onlyDate === true ? 'date' : 'datetime-local'}
        placeholder={props.placeholder}
        id={props.id}
        name={props.id}
        min={props.min ? props.min : ''}
        max={props.max ? props.max : ''}
        required={props.isRequired || false}
        value={props.value}
        onChange={handleChange}
      />
      {error && <p id="input-error">{error}</p>}
    </div>
  )
}
