import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { IPagination } from '../../../interfaces/pagination'

import PaginationLeftIcon from '../icons/pagination-left'
import PaginationDoubleLeftIcon from '../icons/pagination-double-left'
import PaginationRightIcon from '../icons/pagination-right'
import PaginationDoubleRightIcon from '../icons/pagination-double-right'

import { getScoreList } from '../../../store/actions/score'
import { setPagination } from '../../../store/actions/pagination'

interface Props {
  setPage: (payload) => void
  getData: () => void
  pagination: IPagination
}

function Pagination(props: Props) {
  const [state, setState] = useState<IPagination>({
    total: 1,
    page: 1,
    prevPage: null,
    nextPage: null,
    limit: 30,
    newPage: null
  })

  const { t } = useTranslation()

  const handleSubmit = (e) => {
    e.preventDefault()
    props.setPage({ ...state, page: state.newPage as number })
    props.getData()
  }

  const handleSetPagination = (page: number) => {
    props.setPage({ ...state, page })
    props.getData()
  }

  useEffect(() => {
    setState({ ...props.pagination, newPage: null })
  }, [props.pagination])

  return (
    <form className="pagination flex flex-center" onSubmit={handleSubmit}>
      <div className="pagination-action pagination-first">
        <button
          type="button"
          className="pagination-button"
          onClick={() => handleSetPagination(1)}
          disabled={!state.prevPage}
        >
          <PaginationDoubleLeftIcon />
        </button>
      </div>
      <div className="pagination-action pagination-prev">
        <button
          type="button"
          className="pagination-button"
          onClick={() => handleSetPagination(state.prevPage as number)}
          disabled={!state.prevPage}
        >
          <PaginationLeftIcon />
        </button>
      </div>
      <div className="pagination-action pagination-current flex flex-center">
        <input
          id="pagination-page"
          type="number"
          value={state.newPage ?? state.page}
          min={1}
          max={state.total}
          required={true}
          onChange={e => setState({ ...state, newPage: parseInt(e.target.value) })}
        />{' '}
        <span>
          {' '}
          {t('titles.of')} {state.total}{' '}
        </span>
      </div>
      <div className="pagination-action">
        <button
          type="button"
          className="pagination-button"
          onClick={() => handleSetPagination(state.nextPage as number)}
          disabled={!state.nextPage}
        >
          <PaginationRightIcon />
        </button>
      </div>
      <div className="pagination-action">
        <button
          type="button"
          className="pagination-button"
          onClick={() => handleSetPagination(state.total)}
          disabled={!state.nextPage}
        >
          <PaginationDoubleRightIcon />
        </button>
      </div>
    </form>
  )
}

const mapState = (state: any) => ({
  pagination: state.pagination
})

const mapDispatch = (dispatch: any) => ({
  setPage: (payload) => dispatch(setPagination(payload)),
  getData: () => dispatch(getScoreList())
})

export default connect(mapState, mapDispatch)(Pagination)
