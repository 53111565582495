import { AxiosResponse } from 'axios'
import { createFetcher } from '../fetcher'

const fetch = createFetcher()

export async function login(email: string, password: string): Promise<AxiosResponse> {
  try {
    const response = await fetch.post('/v1/auth/login', { email, password })

    return response
  } catch (error) {
    return error.response
  }
}
