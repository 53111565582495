import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import CloseIcon from '../icons/close'

interface Props {
  onClose: () => void
  visible?: boolean
  alignTop?: boolean
  noPadding?: boolean
  noShadow?: boolean
  noBackground?: boolean
  noCloseButton: boolean
  transparent?: boolean
  children: React.ReactNode
}

export default function ModernModal(props: Props) {
  const handleClickOutside = () => props.onClose()

  if (props.visible) {
    return (
      <div className="modern-modal">
        {!props.noCloseButton && (
          <button className="modern-modal__close-button" onClick={props.onClose}>
            <CloseIcon />
          </button>
        )}
        <div
          id="container"
          data-align-top={props.alignTop ? 'on' : 'off'}
          data-enable-padding={props.noPadding ? 'off' : 'on'}
          data-enable-shadow={props.noShadow ? 'off' : 'on'}
          data-enable-background={props.noBackground ? 'off' : 'on'}
        >
          <OutsideClickHandler onOutsideClick={handleClickOutside}>
            {props.children}
          </OutsideClickHandler>
        </div>
      </div>
    )
  } else {
    return <React.Fragment />
  }
}
