import React from 'react'

interface Props {
  label: string;
}

export function TableHeadItem(props: Props) {
  return (
    <div className="table-head-col flex flex-between-center uppercase">
      {props.label}
    </div>
  )
}

export default TableHeadItem
