import { all, takeLatest } from 'redux-saga/effects'

import { getAppToken, appBootstrap } from './app'
import { asyncLogin, asyncLogout } from './auth'
import { asyncGetResult, asyncGetScore, asyncGetScoreReport } from './score'

export default function* rootSaga() {
  yield all([
    takeLatest('APP_BOOTSTRAP', appBootstrap),
    takeLatest('GET_APP_TOKEN', getAppToken),
    takeLatest('ASYNC_GET_SCORE_LIST', asyncGetScore),
    takeLatest('ASYNC_GET_RULES_LIST', asyncGetResult),
    takeLatest('ASYNC_LOGIN_USER', asyncLogin),
    takeLatest('ASYNC_LOGOUT_USER', asyncLogout),
    takeLatest('ASYNC_GET_SCORE_REPORT', asyncGetScoreReport)
  ])
}
