import React from 'react'

import Menu from './menu'
import HeaderLogo from '../header/logo'

export default function Aside(props) {
  return (
    <aside className="aside">
      <div className="aside-body">
        <HeaderLogo />
        <Menu />
      </div>
    </aside>
  )
}
