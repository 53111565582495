import { call, put, select } from 'redux-saga/effects'
import { captureException } from '@sentry/browser'

import { getScore, getResult, exportData } from '../../services/api/score'

import { setRulesList, setScoreList, setTotal } from '../actions/score'
import { setAppLoading } from '../actions/app'
import { setPagination } from '../actions/pagination'
import { logout } from '../actions/auth'

interface Action {
  type: string
  payload: any
}

export function* asyncGetResult() {
  try {
    yield put(setAppLoading(true))

    const result = yield call(getResult)

    if (result.status === 200) {
      const { total, rules } = result.data

      yield put(setTotal(total))
      yield put(setRulesList(rules))
    } else {
      yield put(logout())
    }
  } catch (error) {
    captureException(error)
  } finally {
    yield put(setAppLoading(false))
  }
}

export function* asyncGetScore() {
  const filter = yield select(state => state.filter)
  const pagination = yield select(state => state.pagination)

  try {
    yield put(setAppLoading(true))
    const result = yield call(getScore, { ...filter, page: pagination.page, limit: 10 })

    if (result.status === 200) {
      const { data, ...pagination } = result.data

      yield put(setScoreList(data))
      yield put(
        setPagination({
          total: pagination.total,
          page: pagination.page,
          prevPage: pagination.prevPage,
          nextPage: pagination.nextPage,
          limit: pagination.limit
        })
      )
    } else {
      yield put(logout())
    }
  } catch (error) {
    captureException(error)
  } finally {
    yield put(setAppLoading(false))
  }
}

export function* asyncGetScoreReport() {
  const filter = yield select(state => state.filter)

  try {
    yield put(setAppLoading(true))
    yield call(exportData, { ...filter })
  } catch (error) {
    captureException(error)
  } finally {
    yield put(setAppLoading(false))
  }
}
