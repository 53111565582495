import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { captureException } from '@sentry/browser'

import { setAppToken, setAppLoading, resetAppToken } from '../actions/app'

import { login } from '../../services/api/login'

import * as localStorageHelper from '../../utils/storage'
import { LOCALSTORAGE_SCORE_TOKEN } from '../../config/constants'

interface Action {
  type: string
  payload: any
}

export function* asyncLogin(action: Action) {
  const { payload } = action

  try {
    yield put(setAppLoading(true))

    const result = yield call(login, payload.email, payload.password)

    if (result.status !== 200) {
      // add toast
      console.log('error: please check your login credentials')
      return
    }

    const { token } = result.data

    if (token) {
      localStorageHelper.setString(LOCALSTORAGE_SCORE_TOKEN, token)
      yield put(setAppToken(token))
      yield put(push('/'))
    }
  } catch (error) {
    captureException(error)
  } finally {
    yield put(setAppLoading(false))
  }
}

export function* asyncLogout() {
  try {
    yield put(setAppLoading(true))

    localStorageHelper.clearItem(LOCALSTORAGE_SCORE_TOKEN)

    yield put(resetAppToken())
    yield put(push('/login'))
  } catch (error) {
    captureException(error)
  } finally {
    yield put(setAppLoading(false))
  }
}

export function* asyncRefreshToken() {
  try {
    yield put(setAppLoading(true))
  } catch (error) {
    captureException(error)
    yield put(push('/login'))
  } finally {
    yield put(setAppLoading(false))
  }
}
