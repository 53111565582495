import { IScoreFilter } from '../interfaces/score'

interface ICurrentResult {
  label: string
  value: string
}

export function mountQuery(params: IScoreFilter = {}) {
  const { results, ...rest } = params

  const concatTxtR =
    results
      ?.reduce((acc: string[], current: ICurrentResult) => {
        if (typeof current.value !== 'string') return [...acc]
        return [...acc, `&result=${current.value}`]
      }, [])
      .join('') ?? ''

  const concatTxtF = Object.entries(rest)
    .filter(i => i[1])
    .join('&')
    .replace(/[,]/g, '=')

  if (concatTxtF || concatTxtR) return `?${concatTxtF}${concatTxtR}`
  return ''
}
