import React from 'react'

interface Props {
  active?: boolean
}

export default function ReversesIcon(props: Props) {
  return (
    <svg
      className="svg-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-active={props.active ? 'on' : 'off'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56043 5.4395C8.14643 6.0245 8.14643 6.9755 7.56043 7.5605C6.97443 8.1465 6.02543 8.1465 5.43943 7.5605C4.85343 6.9755 4.85343 6.0245 5.43943 5.4395C6.02543 4.8535 6.97443 4.8535 7.56043 5.4395ZM2.83393 9.89161L8.86593 15.9246L15.9239 8.86661L9.89193 2.83361L2.10693 2.10761L2.83393 9.89161ZM8.86593 17.9986C8.40093 17.9986 7.93693 17.8216 7.58193 17.4686L1.16493 11.0506C0.999926 10.8866 0.897926 10.6686 0.875926 10.4366L0.00392615 1.0926C-0.0230739 0.796605 0.0819261 0.503605 0.292926 0.292605C0.503926 0.081605 0.795926 -0.025395 1.09293 0.00460499L10.4369 0.876605C10.6689 0.897605 10.8859 0.999605 11.0509 1.16461L17.4679 7.5816C17.8109 7.9246 17.9999 8.3806 17.9999 8.8666C17.9999 9.3526 17.8109 9.8086 17.4679 10.1516L10.1509 17.4676C9.79693 17.8216 9.33193 17.9986 8.86593 17.9986Z"
      />
    </svg>
  )
}
