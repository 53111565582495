/**
 * Gets a object from localStorage
 */
export function getObject(key: string): boolean | { [key: string]: unknown } {
  try {
    const rawObject = localStorage.getItem(key)
    if (rawObject) {
      return JSON.parse(rawObject)
    }
    return false
  } catch (err) {
    return false
  }
}

/**
 * Set a object to the localStorage
 */
export function setObject(key: string, payload: { [key: string]: unknown }): boolean {
  try {
    if (typeof payload === 'object') {
      const serializedObject = JSON.stringify(payload)
      localStorage.setItem(key, serializedObject)
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

/**
 * Gets a item as a string from localStorage
 */
export function getString(key: string): string | null {
  return localStorage.getItem(key)
}

/**
 * Set a item as a string to the localStorage
 */
export function setString(key: string, str: string): void {
  return localStorage.setItem(key, str)
}

/**
 * Remove a item in localStorage
 */
export function clearItem(key: string): void {
  return localStorage.removeItem(key)
}
