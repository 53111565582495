import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

interface Props {
  token: string
  component: any
  exact?: boolean
  path: string
}

function PrivateRoute(props: Props) {
  const { component: Component, ...rest } = props

  const routeComponent = () =>
    props.token ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />

  return <Route {...rest} render={routeComponent} />
}

const mapState = (state: any) => ({
  token: state.app.token || null
})

export default connect(mapState)(PrivateRoute)
