import React from 'react'
import { useTranslation } from 'react-i18next'

import ListIcon from '../../icons/list'

interface Props {
  count?: number
}

export function ResumeOpenedCard(props: Props) {
  const { t } = useTranslation()

  return (
    <div
      title={`${t('score.resume_card_opened')}: ${props.count || 0}`}
      className="status-card-container flex flex-vertical status-card-container-square pointer"
    >
      <ListIcon />
    </div>
  )
}

export default ResumeOpenedCard
