const initialState = {
  ready: false,
  loading: false,
  token: null
}

const app = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_APP_READY':
      return { ...state, ready: action.payload }
    case 'SET_APP_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_APP_TOKEN':
      return { ...state, token: action.payload }
    case 'RESET_APP_TOKEN':
      return { ...state, token: null }
    default:
      return state
  }
}

export default app
